import { NavBar, Name, LastName } from '../../util/portfolio.style';

export default function Navigation(props) {
    return (
        <NavBar>
            <Name>
                JOSEPH <br/>
                <LastName color={props.selected[props.selected.selectedCard].bgColor} >
                    ANDERSON
                </LastName>
            </Name>
        </NavBar>
    );
}
