import styled from 'styled-components';
import img from './images/bgimg.jpg';

//GLOBAL STYLES
const theme = {
    space: [
      "0.25rem",
      "0.5rem",
      "0.75rem",
      "1rem",
      "1.5rem",
      "2rem",
      "3rem",
      "4rem",
      "6rem",
      "8rem",
      "12rem",
      "16rem",
      "24rem",
    ]
}

export const Wrapper = styled.div`
    user-select: none;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    margin: 0;
    background-image: url(${img});
    background-size: auto 150vh;
    font-family: 'Helvetica Neue', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`
  
//   export const StyledCode = styled.code`
//    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//      monospace;
// `


//NAV STYLES
export const NavBar = styled.div`
// Positioning
    display: flex;
    align-items: center;
    justify-content: right;
// Visuals
    background-color: #0000008a;
    height: ${theme.space[9]};
`

export const Name = styled.h4`
    text-align: right;
    color: #ffffff;
    font-size: ${theme.space[7]};
    padding-right: ${theme.space[3]};
    line-height: ${theme.space[6]};
`

export const LastName = styled.span`
    color: ${props => props.color};
`

//BODY STYLES
export const MainLayout = styled.div`
    margin-block: ${theme.space[4]};
    display: grid;
    grid-template-columns: ${theme.space[6]} 1fr 1fr ${theme.space[6]};
    grid-gap: ${theme.space[3]};
    @media (max-width: 768px) {
        grid-template-columns: 100%;
        grid-template-rows: ${theme.space[3]} 2fr 1fr ${theme.space[3]};
    }

`
//Big Card
        export const BigCardStyle = styled.div`
            grid-column: 2;
            grid-row: 1;
            aspect-ratio: 1/1;
            @media (max-width: 768px) {
                grid-column: 1;
                grid-row: 2;
                aspect-ratio: 2/1;
                width: 80vw;
                height: auto;
                margin: auto;
            }
            @media (max-width: 575px) {
                min-height: 80vw;
                aspect-ratio: unset;
            }
            background-color:  ${props => props.bg};
            box-shadow: 0px 0px 15px ${props => props.glow};
            border-radius: ${theme.space[1]};
            display: grid;
            grid-template-columns: 50px 1fr 50px;
        `
            
            export const BigCardText = styled.div`
                padding-block: 50px;
                grid-column: 2;
                @media (max-width: 768px) {
                    padding-block: 25px;
                }
            `
            export const BigCardButtons = styled.div`
                display: flex;
                justify-content:  center;
                align-items:  center;
                color: #ffffff;
            `
            export const BigCardTitle = styled.div`
                font-size: ${theme.space[4]};
                margin: 0;
                color: #ffffff;
            `
            export const BigCardLink = styled.a`
                text-decoration: none;
                color: inherit;
            `
            export const BigCardItalics = styled.em`
                font-size: ${theme.space[2]};
            `
            export const FormStyles = styled.form`
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: end;
                align-items: center;
            `
                export const FormInput = styled.input`
                    width: 100%;
                    font: inherit;
                    font-size:inherit;
                    margin-bottom: ${theme.space[1]};
            `
            export const FormMessage = styled.textarea`
                width: 100%;
                resize: none;
                font: inherit;
                font-size:inherit;
                margin-bottom: ${theme.space[1]};
                height: 100px;
                @media (max-width: 768px) {
                    height: unset;
                }
            `
            export const FormButton = styled.button`
                width: 100%;
                font: inherit;
                font-size:inherit;
                border-radius: 0;
                border: none;
            `
//Small Cards
    export const SmallCardStyle = styled.div`
        
        grid-column: 3;
        aspect-ratio: 1/1;
        display: grid;
        grid-template-columns: repeat(2, auto);
        grid-gap: ${theme.space[0]};
        @media (max-width: 768px) {
            grid-column: 1;
            grid-row: 3;
            aspect-ratio: unset;
            margin: auto;
            grid-template-columns: repeat(4, 23.5%);
            grid-template-rows: 100%;
            grid-gap: ${theme.space[1]};
        }
    `
        export const SmallCard = styled.div`
            border-radius: ${theme.space[1]};
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: ${theme.space[4]};
            background-color: ${props => props.bg};
            color: ${props => props.color};
            box-shadow: ${props => props.shadow};
            &:hover {
                box-shadow: 0px 0px 15px ${props => props.glow};
            }
            @media (max-width: 768px) {
                font-size: ${theme.space[3]};
                padding: ${theme.space[5]};
                aspect-ratio: 1/1;
            }
            @media (max-width: 575px) {
                padding: ${theme.space[2]};
            }
        `

//FOOTER STYLES
export const FooterBar = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0000008a;
    height: ${theme.space[9]};
`