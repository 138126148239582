import { MainLayout, SmallCardStyle, BigCardStyle  } from '../util/portfolio.style';
import AboutCard from './BigCards/AboutCard';
import ProjectsCard from './BigCards/ProjectsCard';
import SkillsCard from './BigCards/SkillsCard';
import ContactCard from './BigCards/ContactCard';
import Card from './Card';

const MainUI = (props) => {
    function renderSwitch(param) {
        switch(param) {
            case 'projects':
                return <ProjectsCard
                project={props.selected.projects.pages}
                currentPage={props.selected.currentPage}
                onArrowClick={props.onArrowClick}
                />;
            case 'skills':
                return <SkillsCard
                    skills={props.selected.skills.pages}
                    currentPage={props.selected.currentPage}
                    onArrowClick={props.onArrowClick}
                    />;
            case 'contact':
                return <ContactCard />;
            default:
            return <AboutCard />;
        }
    }
    return (
        <MainLayout>
            <BigCardStyle
                bg={props.selected[props.selected.selectedCard].bgColor}
                glow={props.selected[props.selected.selectedCard].color}>
                {renderSwitch(props.selected.selectedCard)}
            </BigCardStyle>
            <SmallCardStyle>
                <Card title={"about"} {...props} />
                <Card title={"projects"} {...props} />
                <Card title={"contact"} {...props} />
                <Card title={"skills"} {...props} />
            </SmallCardStyle>
        </MainLayout>
    )
}

export default MainUI;