import { BigCardText, BigCardTitle } from "../../util/portfolio.style";

export default function AboutCard(props) {
    return (
        <BigCardText>
                <BigCardTitle>
                    Hi, I'm Joseph.
                </BigCardTitle>
            I love to tinker. <br />
            There is no better feeling than creating
            something useful. Whether 
            hardware, software, systems, or processes, 
            my experience in multiple fields
            gives me a unique perspective 
            and diverse tool kit take on any challenge. 
        </BigCardText>
    )
}