import { BigCardText, BigCardTitle, FormStyles, FormInput, FormMessage, FormButton } from "../../util/portfolio.style";

export default function ContactCard(props) {
    return (
        <BigCardText>
                <BigCardTitle>
                    CONTACT ME:
                </BigCardTitle>
            <FormStyles
                id={"contact-form"}
                onSubmit={(e) => {e.preventDefault()}}
                
            >
                    <label for={"name"} hidden>NAME: </label>
                    <FormInput
                        type={"text"}
                        id={"name"}
                        placeholder={"NAME"}
                        required
                    />
                    <label for={"email"} hidden>EMAIL: </label>
                    <FormInput
                        type={"email"}
                        id={"name"}
                        placeholder={"EMAIL"}
                        required
                    />
                    <label for={"message"} hidden > MESSAGE: </label>
                    <FormMessage
                        form={"contact-form"}
                        id={"message"}
                        placeholder={"MESSAGE"}
                        required
                    />
                    <FormButton
                    type={"submit"}
                    >
                        SUBMIT
                    </FormButton>
            </FormStyles>
        </BigCardText>
    )
}