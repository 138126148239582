import { BigCardText, BigCardTitle, BigCardLink, BigCardItalics, BigCardButtons } from "../../util/portfolio.style";

export default function ProjectsCard(props) {
    return (
        <>
            <BigCardButtons onClick={(e)=>{props.onArrowClick(e,"-")}}>
                ◁
            </BigCardButtons>
            <BigCardText>
                <BigCardTitle>
                    <BigCardLink
                    href={props.project[props.currentPage].link || "https://github.com/josephtanderson"}
                    >
                        {props.project[props.currentPage].title}
                    </BigCardLink>
                </BigCardTitle>
                <BigCardItalics>{props.project[props.currentPage].lang}</BigCardItalics> <br />
                {props.project[props.currentPage].desc}
            </BigCardText>
            <BigCardButtons onClick={(e)=>{props.onArrowClick(e,"+")}}>
                ▷
            </BigCardButtons>
        </>
    )
}