import { FooterBar } from '../../util/portfolio.style'

export default function Footer(props) {
    return (
        <FooterBar>
            <span>GitHub</span>
            <span>/   /</span>
            <span>LinkedIn</span>
        </FooterBar>
    )
}