import { BigCardText, BigCardTitle, BigCardButtons } from "../../util/portfolio.style";

export default function SkillsCard(props) {
    return (
        <>
            <BigCardButtons onClick={(e)=>{props.onArrowClick(e,"+")}}>
                ◁
            </BigCardButtons>
            <BigCardText>
                {props.skills[props.currentPage].map(x => {
                        if (typeof x == "string") {
                            return <BigCardTitle>{x}</BigCardTitle>
                        }
                        return x.map(y => {
                            return <div> {y} </div>
                        })
                    })}
            </BigCardText>
            <BigCardButtons onClick={(e)=>{props.onArrowClick(e,"-")}}>
                ▷
            </BigCardButtons>
        </>
    )
}