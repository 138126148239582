import { SmallCard } from '../util/portfolio.style'

export default function Card(props) {
    let shadow = "none";
    let background = "#00000080";
    let fontColor = "#FFFFFF"
    if (props.selected.selectedCard === props.title) {
        shadow =  "0px 0px 15px " + props.selected[props.title].color;
        background = "#64646480";
        fontColor = props.selected[props.title].bgColor;
    }
    return (
        <SmallCard
            key={props.title}
            bg={background}
            color={fontColor}
            shadow={shadow}
            glow={props.selected[props.title].color}
            onClick={(e)=>{props.onCardClick(e.target.textContent)}}>
                <p>
                    {props.title.toUpperCase()}
                </p>
        </SmallCard>
    )
}