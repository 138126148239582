import { useState } from 'react';

export const usePageState = () => {
    const [ selected, setSelected ] = useState({
        selectedCard: "about",
        currentPage: 0,
        about: {
          color: "#6BD4DB",
          bgColor: "#6BD4DB90",
        }, 
        projects: {
          color: "#DB6B6B",
          bgColor: "#DB6B6BE0",
          pages: [
            {
              title: "Choose Your Own Adventure Game",
              link: "https://github.com/josephtanderson/ChooseYourOwnAdventure",
              lang: "HTML/CSS/JavaScript",
              desc: "My take on a text-based choose your own adventure, written entirely in standard HTML/CSS/JS. Complete with saving, selectable theming, togglable visual effects, and screen resizing."
            },
            {
              title: "TTRPG Live Rolling Web App",
              link: "https://github.com/josephtanderson/live-roller",
              lang: "React, Golang",
              desc: "As a two-person team, we are building a TTRPG companion app that allows virtual rolls to be stored and shared in real-time."
            },
            {
            title: "More Projects",
            link: "https://github.com/josephtanderson",
            lang: "github.com/josephtanderson",
            desc: "To follow what I'm working on now, checkout my GitHub."
            }
            ]
      },      
        contact: {
          color: "#FFA500",
          bgColor: "#FFA50080"
      },
          
        skills: {
          color: "#A36BDB",
          bgColor: "#A36BDBE0",
          pages: [
            ["PEOPLE SKILLS",
              ["Web Design/Development",
              "Brand Management",
              "Fast Learner/Teachable",
              "Media Production",
              "Problem Solving",
              "Process Improvement",
              "Project Management",
              "People Development"]
            ],
            ["TECH SKILLS",
              ["Javascript",
              "React",
              "Git/Version Control",
              "Adobe Creative Suite",
              "HTML/CSS",
              "Bootstrap",
              "Go",
              "npm",
              "Audio/Video Production"]
            ]
          ]
        },
      });
    
    const handleArrowClick = (e, op) => {
        e.preventDefault();
        let i=selected.currentPage;
        if (op === "-") {
            i--;
            if (i<0) {i=selected[selected.selectedCard].pages.length-1};
        }
        if (op === "+") {
            i++;
            if (i>=selected[selected.selectedCard].pages.length) {i=0};
        }
        setSelected({
          ...selected,
            currentPage: i,
        })
    }
    
      const handleCardClick = (val) => {
        let newSelected = val.toLowerCase();
        setSelected({
          ...selected,
          selectedCard: newSelected,
          currentPage: 0,
        });
      }


    return {
        selected,
        handleArrowClick,
        handleCardClick
    }
}