import { usePageState } from './Hooks/usePageState';
import { Wrapper } from './util/portfolio.style';
import Navigation from './components/Nav/Navigation';
import MainUI from './components/MainUI';
import Footer from './components/Nav/Footer';


function Portfolio() {
  const { selected, handleArrowClick, handleCardClick } = usePageState();
  return (
    <Wrapper>
      <Navigation selected={selected} />
      <MainUI onArrowClick={handleArrowClick} selected={selected} onCardClick={handleCardClick} />
      <Footer />
    </Wrapper>
  );
}

export default Portfolio;
